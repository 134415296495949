import type { PageHeadMetaParams } from '../types/seo';
import { setSiteNamePostfixToPageTitle } from '../utils/seo';
import { $t } from '@/localization';

export const CommonPagesHead: PageHeadMetaParams = {
  title: setSiteNamePostfixToPageTitle($t('common-page-title')),
  meta: [
    { name: 'description', content: $t('common-page-meta-description') },
    { name: 'og:title', content: $t('common-page-title') },
    { name: 'og:description', content: $t('common-page-meta-description') },
  ],
};
